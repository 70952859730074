import React from "react";
import SocialShare from "./SocialShare"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


const DoneScreen = ({ isFinishedAll, score, playAgain, emailUrl, sessionId }) => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };


  return (
    <Row className="justify-content-center text-center">
      <Col xs={12}>Done! Your score: <h1 className="text-success">{score}</h1></Col>
      <Col xs={12}>Challenge your friends&nbsp;
        <SocialShare sessionId={sessionId} url={"https://thisimagedoesnotexist.com"}
          title={`Can you tell if an image was generated by a human or a machine? I scored ${score}!`} />
      </Col>
      <Row className="my-3 justify-content-center">
        <Col xs={12} className="justify-content-center align-items-center d-flex">
          <span className="text-muted">Consider supporting this project to keep it running</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="flex-grow-1 align-items-center mb-1">
          <Button className="btn-large" variant="primary" onClick={() => openInNewTab('https://donate.stripe.com/9AQbKN6QxeJP8Pm001')}>Donate <span role="img" aria-label="heart">❤️</span></Button>
        </Col>
        {!isFinishedAll && <Col xs={12} className="pt-2">
          <Button className="btn-large" variant="outline-primary" onClick={playAgain}>Play again with new images</Button>
        </Col>
        }
      </Row>
    </Row>
  );

}

export default DoneScreen;
